
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { clipCreationToolStore, studentCoursesStore } from "../../store";
import { VuetifyMixin, MyStudentCoursesMixin, BAIconsMixin } from '../../mixins';
import { RubricModel } from '../../models/course';
import { filesize } from '../../pipes/filesize.pipe';
import { getMediumDateString, formatDurationShort } from '../../helpers/date';
import { SourceVideoFileDetails } from '../../models/source-video/SourceVideoMuxUpload';
import { SourceVideoMuxModel } from '../../models/source-video/SourceVideoMuxModel';
import * as UpChunk from "@mux/upchunk";
import { sourceVideoMuxModelApi } from '../../api/SourceVideoMuxApi';
import { toFixed } from "@/pipes/to-fixed.pipe";

@Component({
	components: {
	}
})
export default class SourceVideoUploader extends Mixins( MyStudentCoursesMixin, VuetifyMixin, BAIconsMixin) {

	toFixed = toFixed

	@Prop({ default: 140 }) private height: number;
	@Prop({ default: 'baColorOffWhite' }) private backgroundColor: string;
	@Prop({ default: 'baColorLightGray' }) private borderColor: string;
	@Prop() public theFile: File;
	@Prop() public theAssignmentId: string;
	@Prop() public uploadedByUserId: string;
	@Prop() public rubricForAssignment: RubricModel;

	isUploadingSV = false;
	showUpload = false;
	uploadStatus: 'notstarted' | 'uploading' | 'cancelled' | 'completed' | 'error' = 'notstarted';
	theMuxUploadId: string = "";
	theUploadedSourceVideoId: string = "";
	savingSVUploadPercentage = 0;

	selectedVideoGroup: string = "";
	userEnteredName: string = "";

	upChunkUpload: UpChunk.UpChunk;

	get SheetStyle(){
		return {
			'background-color': this.getColor(this.backgroundColor),
			'border': `1px dashed ${this.getColor(this.borderColor)}`,
			'box-sizing': 'border-box',
			'border-radius': '8px',
		};
	}
	
	get ItemListForCriteriaGroupFromRubric() {
		const tempList: {value: string, display: string}[] = [];
		tempList.push({value: '', display: '<none selected>'});  // Nothing chosen
		this.rubricForAssignment.criteriaGroup.forEach(cg => {
			tempList.push({value: cg.name, display: cg.name});
		});

		return tempList;
	}

	// finished(result: string) {
	//   this.theFile = null;

	//   if (result === 'success') {
	//     this.$emit('finished', { status: result, data: this.theUploadedSourceVideoId});
	//   } else {
	//     this.$emit('finished', { status: result, data: ""});
	//   }
	// }

	filesize(value: number): string {
		return filesize(value, 1)
	}

	datestring(theDate: Date): string {
		// console.log("How often does the DateString function get called");
		return getMediumDateString(theDate);
	}

	formatDurationShort(theDuration: number): string {
		return formatDurationShort(theDuration, true, 's');
	}

	formatStartTime(theStartTime: number): string {
		return 'at: ' + formatDurationShort(theStartTime, true, 's');
	}

	deleteThisVideo() {
		console.log('Button pushed to delete the video');
		if (this.uploadStatus === 'completed') {
			console.log("pop a dialog and then remove the video from the DB & mux");
		} else {
			this.$emit('update:removeFile');
		}
	}


	async uploadVideoFile() {
		const file = this.theFile;
		if (file) {
			this.isUploadingSV = true;
			this.savingSVUploadPercentage = 0;
			this.uploadStatus = 'uploading';

			// Do the file upload from here and just get the upload URL from the Backend
			const theUploadDetals: SourceVideoFileDetails = {
				originalFileName: file.name,
				videoUserEnteredName: this.userEnteredName,
				videoUserEnteredDescription: this.selectedVideoGroup,
				size: file.size,
				status: "uploading",
				progress: 0,
				bytesTransferred: 0,
				uploadedByFullUserId: this.uploadedByUserId,
				videoUsageType: 'Evaluation',
				teamEventParentId: "",
				teamParentId: "",
				evaluationAssignmentParentId: this.theAssignmentId,
				atheleteUserParentId: ""
			};

			// const vuexReply: SourceVideoMuxModel = (await clipCreationToolStore.createSVUploadShell(
			//   theUploadDetals
			// )) as SourceVideoMuxModel;

			const svUploadShell: SourceVideoMuxModel = (await sourceVideoMuxModelApi.getDirectUploadURL(
				theUploadDetals
			)) as SourceVideoMuxModel;

			if (svUploadShell) {

				// console.log("Upload about to start ... what did I get back from the store: ", svUploadShell);

				this.theMuxUploadId = svUploadShell.muxVideoUploadId;
				this.theUploadedSourceVideoId = svUploadShell.id;

				this.upChunkUpload = UpChunk.createUpload({
					endpoint: svUploadShell.muxVideoUploadUrl,
					file: file,
					chunkSize: 1024 // Uploads the file in ~1mb chunks
				});

				// subscribe to upload events
				this.upChunkUpload.on("error", (err: any) => {
					console.error("File Uploading Error - ", err.detail);
					if (this.uploadStatus !== 'cancelled') {
						this.uploadStatus = 'error';
					}
				});

				this.upChunkUpload.on("progress", (progress: any) => {
					console.log(
						`So far we've uploaded ${progress.detail}% of ${theUploadDetals.originalFileName}.`
					);
					this.savingSVUploadPercentage = progress.detail;
					if (this.uploadStatus !== 'completed' && this.uploadStatus !== 'cancelled') {
						this.uploadStatus = 'uploading';
					}
				});

				this.upChunkUpload.on("success", () => {
					console.log("File Uploaded successfully");

					// update the assignment.
					this.uploadStatus = 'completed';
					this.isUploadingSV = false;
					this.showUpload = false;
					this.savingSVUploadPercentage = 0;
					this.theMuxUploadId = "";

					studentCoursesStore.addSourceVideoToAssignmentById({
						sourceVideoId: this.theUploadedSourceVideoId, 
						courseId: this.MyStudentCurrentCourseId, 
						studentId: this.MyStudent.coachProfileId,
						assignmentId: this.MyStudentCurrentAssignmentId
					});
				});




				this.upChunkUpload.on("attempt", (msg: any) => {
					// console.log(`attempt callback for ${theUploadDetals.originalFileName}: `, msg.detail);
				});        
				this.upChunkUpload.on("attemptFailure", (msg: any) => {
					console.log(`attemptFailure callback for ${theUploadDetals.originalFileName}: `, msg.detail);
				});
				this.upChunkUpload.on("offline", (msg: any) => {
					console.log(`offline callback for ${theUploadDetals.originalFileName}: `, msg.detail);
				});
				this.upChunkUpload.on("online", (msg: any) => {
					console.log(`online callback for ${theUploadDetals.originalFileName}: `, msg.detail);
				});


			} else {
				// raise a toast message here on the error
				console.log("An error occurred getting the upload url !!! ");
				this.isUploadingSV = false;
				this.showUpload = false;
				this.savingSVUploadPercentage = 0;
				this.theMuxUploadId = "";
			}
		} else {
			console.error("No file found!");
		}
	}

	async resumeVideoUpload() {
		this.upChunkUpload.resume();
	}

	async pauseVideoUpload() {
		this.upChunkUpload.pause();
	}

	async cancelUpload() {
		const cancelReply: any = await clipCreationToolStore.cancelSVUpload(
			this.theMuxUploadId
		);

		// console.log("The cancel finished and the result is: ", cancelReply);

		this.isUploadingSV = false;
		this.showUpload = false;
		this.savingSVUploadPercentage = 0;
		// this.theMuxUploadId = "";
		this.uploadStatus = 'cancelled';

	}


}
