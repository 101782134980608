
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { filesize } from '../../pipes';
import { getMediumDateString, formatDurationShort } from '../../helpers/date';

import {
	videoEditorStore,
} from "../../store";

import VideoEditorOne from "./VideoEditorOne.vue";
import { SourceVideoMuxModel } from '../../models/source-video/SourceVideoMuxModel';

import { MarkupTagClipMuxModel } from "../../models/markup-tag-clip-mux/MarkupTagClipMuxModel";
import RoundedButton from '../forms/RoundedButton.vue';
import AssignmentChip from '../ui/AssignmentChip.vue';
import { RubricModel } from '../../models/course';
import { AssignmentModel } from '../../models/course/AssignmentModel';
import VideoEvaluationClipDetailsForm from './VideoEvaluationClipDetailsForm.vue';
import { VideoOverlayLayoutMixin, BAIconsMixin } from "../../mixins";


@Component({
	components: {
		RoundedButton,
		AssignmentChip,
		VideoEditorOne,
		VideoEvaluationClipDetailsForm
	}
})
export default class VideoEvaluationSingleClipPlayer extends Mixins(VideoOverlayLayoutMixin, BAIconsMixin) {
	@Prop({ default: false, type: Boolean }) value: boolean;
	@Prop() selectedCourseId = '';
	@Prop() selectedCourseName = '';
	@Prop() selectedAssignment: AssignmentModel;
	@Prop() selectedStudentId = '';
	@Prop() selectedStudentName = '';
	@Prop() theRubricInUse: RubricModel;
	@Prop() theClipToPlay: MarkupTagClipMuxModel;

	windowSize: {x:number,y:number} = null;
	onResize(): void{
		this.windowSize = { x: window.innerWidth, y: window.innerHeight }
	}
	get HeaderHeight(): number{
		return 66;
	}
	get ContentHeight(): number{
		if(this.windowSize === null) return 0;
		return this.windowSize.y - this.HeaderHeight;
	}
	get HeaderHeightStyle(): Record<string, any>{
		return {
			'height': `${this.HeaderHeight}px`,
		};
	}
	get ContentHeightStyle(): Record<string, any>{
		return {
			'height': `${this.ContentHeight}px`,
		};
	}
	
	currentSourceVideo: SourceVideoMuxModel | null = null;

	selectedMarkupTagClipId: string = "";

	showingClipEntryForm = false;
	entryClipDetails: MarkupTagClipMuxModel = new MarkupTagClipMuxModel();

	@Watch('value')
	async valueChanged (new_val: boolean, old_val: boolean) {
		if (new_val === true) {
			// set up the editor to load the Clip passed in.
			await videoEditorStore.loadSourceVideoById(this.theClipToPlay.fromMuxSourceVideoId);
			await videoEditorStore.setClipForViewOnlyMode(this.theClipToPlay);
		}
	}

	get show() {
		return this.value;
	}

	get currentlySelectedMarkupTagClipID() {
		return videoEditorStore.selectedClipFromSourceVideoId;
	}

	filesize(value: number): string {
		return filesize(value, 1)
	}

	datestring(theDate: Date): string {
		// console.log("How often does the DateString function get called");
		return getMediumDateString(theDate);
	}

	formatDurationShort(theDuration: number): string {
		return formatDurationShort(theDuration, true, 's');
	}

	formatStartTime(theStartTime: number): string {
		return 'at: ' + formatDurationShort(theStartTime, true, 's');
	}

	async created() {
		// await teamDashboardStore.loadTeam('95cf7117-c6e9-4bbd-a59b-9ca365623a33');
		// console.log("In the Created life cycle event of the ClipCreatorEvent page");
		await videoEditorStore.setupStore();
		// await clipCreationToolStore.loadSourceVideosFromDB({ emptyParam: 'hello' });
	}

	viewTheRubric() {
		console.log("this viewTheRubric function is not implemented currently")
	}

	dragMove(e: MouseEvent) {
		// console.log('The drag move has occurred, and the event is: ', e)
		if (videoEditorStore.isDragging || videoEditorStore.isDraggingLeftMarker || videoEditorStore.isDraggingRightMarker) {
			videoEditorStore.dragMove(e);
		}
	}

	dragStop(e: MouseEvent, stopType: string) {
		// console.log('********************************************************')
		// console.log('The drag stop has occurred, and the event is: ', e)
		// console.log('the element id is: ', (e.srcElement as HTMLDivElement).id)
		// console.log('the Offset x is: ', e.offsetX)
		// console.log('the Offset left is: ', (e.srcElement as HTMLDivElement).offsetLeft)
		// console.log('The parent element is: ', ((e.srcElement as HTMLDivElement).offsetParent as HTMLDivElement).id)
		// console.log('The parent element ofset left is: ', ((e.srcElement as HTMLDivElement).offsetParent as HTMLDivElement).offsetLeft)
		// console.log('********************************************************')
		if (videoEditorStore.isDragging || videoEditorStore.isDraggingLeftMarker || videoEditorStore.isDraggingRightMarker) {
			if (stopType === "up") {
				videoEditorStore.dragStopOnMouseUp(e);
			} else {
				videoEditorStore.dragStopOnMouseLeave(e);
			}
		}
	}

	closeStudio() {
		// console.log("!!!****  Close Stud")
		videoEditorStore.pausePlaying();
		this.value = false;
		this.$emit('update:value', this.value);
	}

	onResizeEditorPage() {
		console.log("In hte VideoEditorSingleClipPlayer ... Got the page resize event !! -  Set the offset.");
		try {
			videoEditorStore.setPlayerLeftOffset(
				(this.$refs.fullContent as HTMLDivElement).offsetLeft
			);
		} catch (error) {
			console.log("Error getting the left offset of the Video editor")
		}

	}

	async mounted() {
		// console.log("IN VideoClipCreator MOUNTED method ... ")
		this.$nextTick(() => {
			// console.log("In the nextTick of the ClipCreatorEditor mounted routine -- regestering the resize event listener");
			window.addEventListener("resize", this.onResizeEditorPage);
			this.onResizeEditorPage();
		});

		// this.theRubricInUse = this.RubricTemplates[0];
		// console.log("!!!! the rubric in use is: ", this.theRubricInUse);
		// console.log("!!!! the rubric templates are: ", this.RubricTemplates);
	}

	async beforeDestroy() {
		// console.log("IN VideoClipCreator BEFOREDESTROY method ... ")
		window.removeEventListener("resize", this.onResizeEditorPage);
		// leaving the page ... so remove the current teamUID ... but don't unload the data.
		// console.log("Does this get called on course switch ?? ");
		videoEditorStore.teardownVidStore();
		// clipCreationToolStore.teardownTeamEventStore();
	}
}
