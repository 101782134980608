
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '../../mixins';
import AssignmentChip from '../ui/AssignmentChip.vue';
import { AssignmentModel } from '../../models/course';
import { ContextMenuItem } from '@/../types/interfaces';
import { AssignmentStatus } from '@/../types/enums';
import{mdiUpload, mdiClipboardTextOutline} from '@mdi/js'
import { StudentViewEvaluation, AssignmentSubmission } from '@/../types/constants/web_client_user.routes';

@Component({
	components: { AssignmentChip },
})
export default class AssignmentCard extends Mixins(VuetifyMixin, BAIconsMixin){
	@Prop({ required: true }) assignment: AssignmentModel;
	get CardStyle(): Record<string, unknown>{
		return {
			'border-bottom': `8px solid ${this.getColor(this.assignment.color)} !important`,
		};
	}

	get AssignmentIsCompleted(): boolean{
		return this.assignment.status === AssignmentStatus.Completed;
	}

	get MenuItems(): ContextMenuItem[]{
		const items = [
			{
				text: 'Update Submission',
				icon: mdiUpload,
				click: () => this.uploadVideos(),
			}
		];
		if(this.assignment.evaluation !== null && this.assignment.evaluation !== undefined){
			items.unshift({
				text: 'View Evaluation',
				icon: mdiClipboardTextOutline,
				click: () => this.viewEvaluation(this.assignment.id),
			});
		}
		return items;
	}

	viewEvaluation(assignmentId: string): void{
		this.$router.push({
			name: StudentViewEvaluation,
			params:{
				assignmentId: assignmentId,
			}
		});
	}
	uploadVideos(): void{
		if(this.AssignmentIsCompleted){
			this.viewEvaluation(this.assignment.id);
		}else{
			this.$router.push({
				name: AssignmentSubmission,
				params:{
					assignmentId: this.assignment.id,
				}
			});
		}
	}

	openUrl(url: string): void{
		window.open(url, '_');
	}

	get IsCompleted(): boolean{
		return this.assignment.status === AssignmentStatus.Completed;
	}
	get IsInProgress(): boolean{
		return this.assignment.status === AssignmentStatus.InProgress;
	}
	get IsReadyForReview(): boolean{
		return this.assignment.status === AssignmentStatus.ReadyForReview;
	}
	get IsNotStarted(): boolean{
		return this.assignment.status === AssignmentStatus.NotStarted;
	}
}
